import * as actions from '../actions';

let queuedAction;

const collectStats = store => next => action => {
  if(action.type !== actions.COLLECT_STATS) {
    if(queuedAction && action.type === actions.LOAD_ETHERNAUT_CONTRACT && action.contract) {
      // console.log(`RETRIGGER`)
      next(action)
      store.dispatch(queuedAction)
      queuedAction = null
      return
    }
    return next(action)
  }

  const state = store.getState()
  // console.log(`TRY COLLECT_STATS >`, state.contracts.ethernaut !== undefined)
  if(
    !state.network.web3 ||
    !state.contracts.ethernaut
  ) {
    if(!queuedAction) queuedAction = action
    return
  }


  // Get Level created
  if(!action.scores) {
    let limit = 100;
    state.contracts.ethernaut.playersCnt()
        .then(result=> {
            result = result.toNumber();
            //console.log('Players cnt:', result);
            return Math.floor(result / limit) + (result % limit > 0 ? 1 : 0);
        })
        .then(count => {
          let promises = [];
          for (let i = 0; i < count; ++i) {
            promises.push(state.contracts.ethernaut.getPlayers(i * limit, (i + 1) * limit));
          }
          //console.log('Created requests:', promises.length);
          return Promise.all(promises);
        })
        .then(results => {
          let players = [];
          results.forEach(res => {
            players = players.concat(res);
          })
          return players;
        })
        .then(players => {
          //console.log('Fetched players:', players.length);
          //console.log('Players:', players);
          action.scores = players;
          store.dispatch(action);
        })
  }

  next(action)
}

export default collectStats
