import React from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import * as actions from '../actions'
import _ from 'lodash'

import '../styles/scoreboard.css'

class Scoreboard extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      scores: [],
      levels: [],
    }
    this.interval = null;

    this._updateState(props);
  }


  componentDidMount() {
    this.props.collectStats();
   // this.interval = setInterval(() => this.props.collectStats(), 20000);
    this._updateState(this.props)
  }

  componentWillUnmount() {
    if (this.interval) {
      console.log('removed interval');
      clearInterval(this.interval);
      this.interval = null;
    }
  }

  _updateState(props) {
    if (props === undefined || props.levels === undefined) return;
    
    let scores = props.scores;

    console.log('scores', scores);
    console.log('levels', props.levels);

    // Sort data
    scores = _.sortBy(scores, (obj) => parseInt(obj.totalScores, 10))

    //console.log('updating state', scores, levels, levelIdx)
    this.setState({
      scores,
      levels: props.levels,
    })
  }

  componentWillReceiveProps(nextProps) {
    this._updateState(nextProps)
  }


  render() {
    const query = new URLSearchParams(this.props.location.search);
    const needAddr = query.get('addr') != null;
    return (
      <div className="page-container">
        <table className="scoreboard">
        <thead>
        <tr>
            <th>#</th>
            <th>Player</th>
            {_.map(this.state.levels, (level, idx) => {
                return (
                    <th key={"level-"+idx}>Level #{parseInt(level.idx) + 1}</th>
                )
            })}
            <th>Total score</th>
        </tr>
        </thead>
        <tbody>
        {_.map(_.orderBy(this.state.scores, (obj) => parseInt(obj.totalScores, 10), ['desc']), (item, idx) => {
            return (
            <tr key={item.player}>
                <td>{idx + 1}</td>
                <td>{needAddr ? item.player + ' ' : ''}{item.nick}</td>
                {_.map(item.scores, (score, idx) => {
                    return (
                        <td key={idx}>{score}</td>
                    )
                })}
                <td>{item.totalScores}</td>
            </tr>
            )
        })}
        </tbody>
        </table>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    scores: state.stats.scores,
    levels: state.gamedata.levels
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    collectStats: actions.collectStats
  }, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Scoreboard)
