import dotenv from 'dotenv';
import pkgWeb3 from 'web3';
dotenv.config()

export const DEBUG = process.env.NODE_ENV !== 'production'
export const DEBUG_REDUX = DEBUG
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN

// Networks
export const NETWORKS = {
  UNDEFINED: undefined,
  LOCAL: {
    name: 'local',
    id: '*',
    // url: 'http://localhost',
    url: 'http://host.docker.internal',
    port: 8545
  },
  ROPSTEN: {
    name: 'ropsten',
    id: '3',
    url: `${process.env.ROPSTEN_HOST}`,
    privKey: `${process.env.ROPSTEN_PRIV_KEY}`
  },
  RINKEBY: {
    name: 'rinkeby',
    id: '4',
    url: `${process.env.RINKEBY_HOST}`,
    privKey: `${process.env.RINKEBY_PRIV_KEY}`
  },
  GOERLI: {
    name: 'goerli',
    id: '5',
    url: `${process.env.GOERLI_HOST}`,
    privKey: `${process.env.GOERLI_PRIV_KEY}`
  },
  SEPOLIA: {
    name: 'sepolia',
    id: '11155111',
    url: 'https://sepolia.infura.io/v3/fb0ef896b8e2437db142a845307d3923',
    privKey: `${process.env.SEPOLIA_PRIV_KEY}`
  }
}

// Web3
export const Web3 = pkgWeb3;

// Misc
export const CLEAR_CONSOLE = !DEBUG
export const GOOGLE_ANALYTICS_ID = 'UA-85043059-4'

// Owner addresses
export const ADDRESSES = {
  [NETWORKS.LOCAL.name]: undefined,
  [NETWORKS.ROPSTEN.name]: '0xFEA30A3F536E118b1bb31e3a2b9b4389dBe054EA',
  [NETWORKS.RINKEBY.name]: '0xFEA30A3F536E118b1bb31e3a2b9b4389dBe054EA',
  [NETWORKS.GOERLI.name]: '0xFEA30A3F536E118b1bb31e3a2b9b4389dBe054EA',
  [NETWORKS.SEPOLIA.name]: '0xFEA30A3F536E118b1bb31e3a2b9b4389dBe054EA'
}

// Storage
export const VERSION = "0.1.0";
export const STORAGE_PLAYER_DATA_KEY = `ethernaut_player_data_${VERSION}_`

// Paths
export const PATH_ROOT = '/'
export const PATH_NOT_FOUND = '/404'
export const PATH_HELP = '/help'
export const PATH_LEVEL_ROOT = `${PATH_ROOT}level/`
export const PATH_LEVEL = `${PATH_LEVEL_ROOT}:address`
export const PATH_STATS = `${PATH_ROOT}stats`
export const PATH_SCOREBOARD = `${PATH_ROOT}scoreboard`

// RELEASE SENSITIVE
// -----------------------------------------------------------------------------------------
// -----------------------------------------------------------------------------------------
export const CUSTOM_LOGGING = true                              /* TRUE on production */
export const SHOW_ALL_COMPLETE_DESCRIPTIONS = false             /* FALSE on production */
export const SHOW_VERSION = true                                /* TRUE on production */
// export const ACTIVE_NETWORK = NETWORKS.ROPSTEN
 export const ACTIVE_NETWORK = NETWORKS.SEPOLIA
// export const ACTIVE_NETWORK = NETWORKS.LOCAL
// -----------------------------------------------------------------------------------------
// -----------------------------------------------------------------------------------------
