import * as actions from '../actions';

const initialState = {
  scores: []
}

const statsReducer = function(state = initialState, action) {
  let newState = { ...state }
  switch(action.type) {

    case actions.COLLECT_STATS:
      if(action.scores) {
        newState.scores = action.scores;
      }

      break

    default:
      break
  }
  return newState;
}

export default statsReducer